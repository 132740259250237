import BackButton from "components/layout/BackButton";
import QPageLayout from "components/layout/QPageLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Typography, Flex, theme } from "antd";
import {
  CodeSandboxOutlined,
  CopyOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import BudgetCard from "components/tenant/budgets/BudgetCard";
import AccountBudgeting from "components/tenant/budgets/AccountBudgeting";
import ItemBudgeting from "components/tenant/budgets/ItemBudgeting";
const { useToken } = theme;

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
    {
      title: t("dashboard.quick_actions.accounting_header"),
    },
    {
      title: <a href="/tenant/budgets">{t("budgets.name")}</a>,
    },
    {
      title: t("budgets.select_budget"),
    },
  ];
};

const New = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  const breadCrumbData = useBreadCrumbData();
  const [messageApi, contextHolder] = message.useMessage();
  const { Text } = Typography;
  type BudgetType = {
    id: number;
    name: string;
    icon: any;
    text: string;
  };
  const [budgetTypes, setBudgetTypes] = useState<BudgetType[]>([
    {
      id: 1,
      name: "items",
      icon: <CopyOutlined style={{ fontSize: "40px" }} />,
      text: t("budgets.item_budgeting.name"),
    },
    {
      id: 2,
      name: "accounts",
      icon: <CodeSandboxOutlined style={{ fontSize: "40px" }} />,
      text: t("budgets.accounts_budgeting.name"),
    },
  ]);

  const [selectedBudget, setSelectedBudget] = useState<string | null>(null);

  const headerStyle = {
    color: token.colorPrimary,
    fontSize: "24px",
  };

  return (
    <QPageLayout
      title={t("budgets.new")}
      actionBtns={<BackButton />}
      breadCrumbData={breadCrumbData}
    >
      <>
        {!selectedBudget ? (
          <Flex
            gap={"64px"}
            style={{ minHeight: "60vh" }}
            justify="center"
            align="center"
            vertical
          >
            <Text style={headerStyle}>{t("budgets.select_budget")}</Text>
            <Flex justify="center" align="center" gap={"42px"} wrap={"wrap"}>
              {budgetTypes.map((el, i) => {
                return (
                  <Flex
                    key={el.id}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setSelectedBudget(el.name);
                    }}
                  >
                    <BudgetCard text={el.text} icon={el.icon}></BudgetCard>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        ) : selectedBudget == "accounts" ? (
          <AccountBudgeting />
        ) : (
          <ItemBudgeting />
        )}
      </>
    </QPageLayout>
  );
};

export default New;
