import React, { useEffect, useRef, useState } from 'react'
import { Button, Space, Table, TableProps } from 'antd'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next';
import QPageLayout from 'components/layout/QPageLayout';
import { useQuery } from 'react-query';
import { getDimension, getDimensionValues } from 'api/dimensions';
import { DimensionType, DimensionValue, DimensionValueResponse } from 'types/dimensions';
import { PlusOutlined } from '@ant-design/icons';
import { useDimensionPermissions } from 'hooks/dimensions';

export const useBreadCrumbData = (dimensionRecord: DimensionType) => {
  const { t } = useTranslation();
  return [
      {
        title: t('activerecord.attributes.layout.settings'),
      },
      {
        title: <a href="/tenant/dimensions">{t('activerecord.attributes.dimension.name')}</a>,
      },
      {
        title: <a href={`/tenant/dimensions/${dimensionRecord?.id ?? ''}`}>{dimensionRecord?.code ?? ''}</a>,
      },
      {
        title: t('activerecord.attributes.dimension_value.name'),
      },
  ]
}

const LOCALIZED_VALUES = new Map([['parent_value', 'activerecord.attributes.dimension_value.value_types.parent_value'], ['standard', 'activerecord.attributes.dimension_value.value_types.standard']])


const useColumns = (): TableProps<DimensionValue>['columns'] => {
  const { t } = useTranslation();
  return ([
    {
      title: t('activerecord.attributes.dimension.index.code'),
      dataIndex: 'code',
      width: '25%'
    },
    {
      title: t('activerecord.attributes.dimension.index.name'),
      width: '37.5%',
      key: 'name',
      dataIndex: 'name',
      render: (_, record) => (
        i18next.language === 'en'? record.name_en : record.name_ar
      )
    },
    {
      title: t('activerecord.attributes.dimension_value.value_type'),
      width: '37.5%',
      dataIndex: 'value_type',
      render: (_, record) => {
        return t(LOCALIZED_VALUES.get(record.value_type) ?? '');
      }
    },
  ])
}

const DimensionValueIndex = ({ id }) => {
  
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const fetchedValues = useRef(new Map());
  const [data, setData] = useState([]);
  const [activeParent, setActiveParent] = useState(0);
  const { permissions } = useDimensionPermissions();

  const dimensionQuery = useQuery<DimensionType>({
    queryKey: ['dimension'],
    queryFn: getDimension.bind(null, { dimensionID: id })
  })

  const breadCrumbData = useBreadCrumbData(dimensionQuery.data);
  const columns = useColumns();

  const rootDimensionValues = useQuery<DimensionValueResponse>(['rootDimensionValues', page], getDimensionValues.bind(null, { dimensionID: id, page, parentID: null }));

  const dimensionValues = useQuery<DimensionValueResponse>(['dimensionValues', activeParent], getDimensionValues.bind(null, { dimensionID: id, page: 1, parentID: activeParent }), {
    enabled: Boolean(activeParent)
  });


  useEffect(() => {
    if(Boolean(rootDimensionValues.data)) {      
      const newData = rootDimensionValues.data.records.map(record => {
        fetchedValues.current.set(record.id, record.id);
        const row = {...record};
        if(record.value_type === 'parent_value') {
          row['children'] = [];
        }
        return row;
      })
      setData(newData);
    }
  }, [rootDimensionValues.data])

  useEffect(() => {
    if(Boolean(activeParent) && Boolean(dimensionValues.data?.records.length)) {
      const newDataBranch = dimensionValues.data.records.map(record => {
        fetchedValues.current.set(record.id, activeParent);
        const row = {...record};
        if(record.value_type === 'parent_value') {
          row['children'] = [];
        }
        return row;
      })

      newDataBranch.sort((a, b) => b.value_type.localeCompare(a.value_type))
     
      let parentID = activeParent;
      
      const path = [parentID];
      while(Boolean(parentID) && parentID !== fetchedValues.current.get(parentID)) {
        parentID = fetchedValues.current.get(parentID)
        path.push(parentID);
      }
      path.reverse();
      setData(state => {
        let tmpData = state;
        for(let i = 0; i < path.length; i++) {
          const id = path[i];
          const idx = tmpData.findIndex(record => record.id == id);
          if(i === path.length - 1) {
            tmpData[idx].children = newDataBranch;
          } else {
            tmpData = tmpData[idx].children;
          }
        }
        return [...state]
      })
      
    }
  }, [dimensionValues.data, activeParent])

  const handleExpandRow = (expanded, record) => {
    if(expanded) {
      setActiveParent(record.id);
    }
  }
  
  
  let actionButtons = (
    <Space>
      {permissions?.write && (
        <Button icon={<PlusOutlined />} type="primary" href={`/tenant/dimensions/${id}/values/new`}>{t('activerecord.attributes.dimension.view.actions.add_dimension_value')}</Button>
      )}
    </Space>
  )

  
  return (
    <QPageLayout title={t('activerecord.attributes.dimension_value.index.title', { code: dimensionQuery.data?.code ?? '' })} actionBtns={actionButtons} breadCrumbData={breadCrumbData} >
      <Table expandable={{onExpand: handleExpandRow}} rowKey="id" loading={rootDimensionValues.isLoading} columns={columns} dataSource={data ?? []} onChange={({current}) => { setPage(current); setActiveParent(0) }} pagination={{total: rootDimensionValues.data?.pagination.total_entries ?? 0}} />
    </QPageLayout>
  )
}

export default DimensionValueIndex
