import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import QPageLayout from 'components/layout/QPageLayout';
import QEmptyIndex from 'components/layout/emptyIndex';
import EmptyDimensionIcon from 'assets/icons/empty-dimension.svg'
import { Button, Table, Space, TableProps, Tooltip, Spin, Flex, message, Form, Input } from 'antd';
import { FilterOutlined, PlusOutlined, UndoOutlined, EyeOutlined, SettingOutlined, EditOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import axios from 'axios';
import i18next from 'i18next';
import { SorterResult } from 'antd/es/table/interface';
import { useInteractiveFilter } from 'hooks/app';
import { Permissions } from 'types/app';
import { useDimensionPermissions } from 'hooks/dimensions';

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.settings'),
      },
      {
          title: t('activerecord.attributes.dimension.name'),
      }
  ]
}

export interface DataType {
  id: number;
  code: string;
  name_en: string;
  name_ar: string;
  description: string;
  global: boolean;
}

const LOCALIZED_VALUES = new Map([['name', {en: 'name_en', ar: 'name_ar'}]])
const LOCALIZED_VALUES_REVERSE = new Map([['name_en', 'name'], ['name_ar', 'name']])

const getSorterKey = (key) => {
  if(LOCALIZED_VALUES.has(key)) {
    return LOCALIZED_VALUES.get(key)[i18next.language]
  }
  return key;
}

const useColumns = (sorter: {field: string, order: string}, permissions: Permissions): TableProps<DataType>['columns'] => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('activerecord.attributes.dimension.index.code'),
      dataIndex: 'code',
      width: '12.5%',
      sorter: true,
      showSorterTooltip: false
    },
    {
      title: t('activerecord.attributes.dimension.index.name'),
      width: '37.5%',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        i18next.language === 'en'? record.name_en : record.name_ar
      )
    },
    {
      title: t('activerecord.attributes.dimension.index.description'),
      dataIndex: 'description',
      width: '37.5%'
    },
    {
      title: t('activerecord.attributes.dimension.index.actions.title'),
      key: 'action',
      width: '12.5%',
      render: (_, record) => (
        <Space size="middle">
          {permissions?.write && (
            <>
              <Tooltip placement="top" title={t('activerecord.attributes.dimension.index.actions.add_value')}>
                <Button href={`/tenant/dimensions/${record.id}/values/new`} icon={<PlusOutlined />} />
              </Tooltip>
              <Button href={`/tenant/dimensions/${record.id}/edit`} icon={<EditOutlined />} />
            </>
          )}
          <Button href={`/tenant/dimensions/${record.id}`} icon={<EyeOutlined />} />
        </Space>
      ),
    },
  ]

  if(sorter) {
    const sortedColumnIdx = columns.findIndex(col => col.dataIndex === (LOCALIZED_VALUES_REVERSE.get(sorter.field) ?? sorter.field));
    if(sortedColumnIdx > -1) {
      columns[sortedColumnIdx]['defaultSortOrder'] = sorter.order;
    }
  }

  return columns;
} 


const DimensionIndex = ({}) => {
  const { t } = useTranslation();
  const breadCrumbData = useBreadCrumbData();
  const [messageApi, contextHolder] = message.useMessage();
  const [ filterUpdated, setFilterUpdate ] = useState<boolean>(false);
  const { permissions } = useDimensionPermissions();
  
  const { url, page, pageSize, sorter, update, setFormContainerRef, form, filterApplied } = useInteractiveFilter({ setFilterUpdate, path: '/tenant/dimensions.json' }, [filterUpdated])
  
  const columns = useColumns(sorter.get(), permissions);

  const onChange: TableProps<DataType>['onChange'] = useCallback((pagination, _, sorterObject) => {  
    page.set(pagination.current);
    pageSize.set(pagination.pageSize);
    const { order, field } = sorterObject as SorterResult<DataType>;
    sorter.set({field: getSorterKey(field as string), order});
    
  }, [])

  const handleFilterSubmit = useCallback(() => {
    update();
  }, [])

  const handleFilterReset = useCallback(() => {
    form.resetFields();
    update();
  }, [])


  const { data, isLoading, isError, isFetching } = useQuery(['dimensions', url], async () => {
    return (await axios.get(url)).data
  }, { keepPreviousData: true });

  useEffect(() => {
    if(isError) {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error')
      });
    }
  }, [ isError ])
  
  let actionButtons = null; 
  
  let isEmptyIndex = data?.pagination.total_entries === 0 && !filterApplied;

  if(!isEmptyIndex) {
    actionButtons = (
      <Space>
        {permissions?.write && (
          <>
            <Button icon={<SettingOutlined />} type="primary" href='/tenant/dimensions/configuration'>{t('activerecord.attributes.dimension.configuration.title')}</Button>
            <Button icon={<PlusOutlined />} type="primary" href='/tenant/dimensions/new'>{t('activerecord.attributes.dimension.index.actions.add')}</Button>
          </>
        )}
      </Space>
    )
  }
  
  return (
    <QPageLayout title={t('activerecord.attributes.dimension.name_plural')} 
      actionBtns={actionButtons}
      breadCrumbData={breadCrumbData} messageHolder={ contextHolder }>
      {
        isLoading? 
          <Flex style={{ height: '5em' }} align='center' justify='center'><Spin /></Flex> 
          :
          !isEmptyIndex || isFetching?
            (
              <>
                <div ref={setFormContainerRef}>
                  <Form
                    form={form}
                    name='dimensionIdx'
                    onFinish={handleFilterSubmit}
                    layout='inline'
                  >
                    <Form.Item
                      name="q[code_cont]"
                    >
                      <Input maxLength={25} placeholder={t('activerecord.attributes.dimension.index.code')} />
                    </Form.Item>
                    <Form.Item
                      name="q[name_ar_or_name_en_cont]"
                    >
                      <Input maxLength={25} placeholder={t('activerecord.attributes.dimension.index.name')} />
                    </Form.Item>
                    <Form.Item>
                      <Space>
                        <Button icon={<FilterOutlined />} htmlType='submit' type="primary">{t('forms.buttons.filter')}</Button>
                        <Button onClick={handleFilterReset} icon={<UndoOutlined />} danger>{t('forms.buttons.reset')}</Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </div>
                <Table rowKey="id" loading={isFetching} columns={columns} dataSource={data?.records} pagination={{total: data?.pagination.total_entries, current: data?.pagination.current_page, pageSize: pageSize.get(), showSizeChanger: true, pageSizeOptions: [15, 25, 50, 75, 100]}} onChange={onChange} />
              </>
            ) : (
              <QEmptyIndex title={t('activerecord.attributes.dimension.index.title')} subTitle={t('activerecord.attributes.dimension.index.sub_title')} Icon={ EmptyDimensionIcon }>
                {permissions?.write && (
                  <Button icon={<PlusOutlined />} type="primary" href='/tenant/dimensions/new'>{t('activerecord.attributes.dimension.index.actions.add')}</Button>
                )}
              </QEmptyIndex>
            )
      }
    </QPageLayout>
  )
}

export default DimensionIndex