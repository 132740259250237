import { useTranslation } from "react-i18next";
import i18next from "i18next";
import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import { EyeOutlined, EditOutlined, SyncOutlined } from "@ant-design/icons";
import moment from "moment";
import { ColumnsType } from "antd/es/table";

export const useTableColumns = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("budgets.attributes.reference"),
      dataIndex: "code",
      key: "id",
      sorter: true,
      SortOrder: "descend",
    },
    {
      title: t("budgets.attributes.name"),
      dataIndex: i18next.language === "en" ? "name_en" : "name_ar",
      key: i18next.language === "en" ? "name_en" : "name_ar",
      sorter: true,
    },
    {
      title: t("budgets.attributes.budget_type"),
      dataIndex: "kind",
      key: "kind",
      sorter: true,
    },
    {
      title: t("budgets.attributes.fiscal_year"),
      dataIndex: "fiscal_year",
      key: "fiscal_year",
      sorter: true,
    },
    {
      title: t("budgets.attributes.actions"),
      key: "actions",
      render: (text, record) => (
        <>
          <Button
            icon={<EyeOutlined />}
            href={`/tenant/budgets/${record.id}`}
            style={{ marginRight: 8 }}
          />
          <Button
            icon={<EditOutlined />}
            href={`/tenant/budgets/${record.id}/edit`}
            style={{ marginRight: 8 }}
          />
        </>
      ),
    },
  ];

  return {
    columns,
  };
};

export const useBudgetDetailsColumns = (
  dateRanges,
  cycle,
  formData,
  handleInputChange,
  showResetRowModal,
  data,
): ColumnsType<any> => {
  const { t } = useTranslation();
  const renderEditableInput = (record, field) => {
    const isDisabled = record.children && record.children.length > 0;
    return (
      <Input
        type="number"
        style={{ width: cycle.no === 12 ? "130px" : "150px" }}
        value={formData[record.id]?.[field] || 0}
        onChange={(e) => handleInputChange(e, record.id, field)}
        disabled={isDisabled}
      />
    );
  };
  const columns: ColumnsType<any> = [
    {
      title: t("budgets.show.budget_details.codes"),
      dataIndex: "code",
      key: "id",
      width: 180,
      fixed: "left",
    },
    {
      title: t("budgets.show.budget_details.name"),
      dataIndex: "name",
      key: "name",
      width: 150,
      fixed: "left",
    },
    {
      title: data?.base === 'quantity' ? 
        `${t("budgets.show.budget_details.budget_quantity")}` : 
        `${t("budgets.show.budget_details.budget_amount")} `,
      dataIndex: "budget_amount",
      key: "budget_amount",
      fixed: "left",
      render: (_, record) => (
        <Input
          style={{ width: cycle.no === 12 ? "130px" : "150px" }}
          value={formData[record.id]?.budget_amount || 0}
          disabled
        />
      ),
    },
    ...(Array.isArray(dateRanges)
      ? dateRanges.map((year, i) => {
          const date = moment(year, "D-MM-YYYY");
          const fiscalYear = date.year();
          const shortName = date.format("MMM");
          let title;
          if (dateRanges.length === 4) {
            const startShortName = moment(year[0], "D-MM-YYYY").format("MMM");
            const endShortName = moment(year[1], "D-MM-YYYY").format("MMM");
            title = `${startShortName}-${endShortName} ${fiscalYear}`;
          } else {
            title = `${shortName} ${fiscalYear}`;
          }
          return {
            title: title,
            dataIndex: i + 1,
            key: `${i + 1}_${fiscalYear}`,
            width: cycle.no === 12 ? 100 : 200,
            render: (_, record) => renderEditableInput(record, i + 1),
          };
        })
      : []),
    {
      title: "",
      key: "resetIcon",
      fixed: "right",
      render: (_, record) => {
        return record.children && record.children.length > 0 ? null : (
          <SyncOutlined
            style={{ fontSize: "20px" }}
            onClick={() => showResetRowModal(record.id)}
          />
        );
      },
    },
  ];
  return columns;
};

export const useResetAllModal = (handleReset) => {
  const { t } = useTranslation();
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);

  const showResetModal = () => {
    setIsResetModalVisible(true);
  };

  const handleCancel = () => {
    setIsResetModalVisible(false);
  };

  const handleOk = () => {
    handleReset();
    setIsResetModalVisible(false);
  };

  const resetModal = (
    <Modal
      title={t("budgets.reset_details_popup.title")}
      visible={isResetModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t("budgets.reset_details_popup.confirm")}
      cancelText={t("budgets.reset_details_popup.cancel")}
    >
      <p>{t("budgets.reset_details_popup.description")}</p>
    </Modal>
  );

  return {
    showResetModal,
    resetModal,
  };
};

export const useResetRowDataModal = (handleResetRow) => {
  const { t } = useTranslation();
  const [isResetRowModalVisible, setIsResetRowModalVisible] = useState(false);
  const [currentRecordId, setCurrentRecordId] = useState(null);

  const showResetRowModal = (recordId) => {
    setCurrentRecordId(recordId);
    setIsResetRowModalVisible(true);
  };

  const handleCancel = () => {
    setIsResetRowModalVisible(false);
  };

  const handleOk = () => {
    if (currentRecordId !== null) {
      handleResetRow(currentRecordId);
    }
    setIsResetRowModalVisible(false);
  };

  const resetRowModal = (
    <Modal
      title={t("budgets.reset_row_popup.title")}
      visible={isResetRowModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t("budgets.reset_row_popup.confirm")}
      cancelText={t("budgets.reset_row_popup.cancel")}
    >
      <p>{t("budgets.reset_row_popup.description")}</p>
    </Modal>
  );

  return {
    showResetRowModal,
    resetRowModal,
  };
};

