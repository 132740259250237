import React, { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Form, FormListFieldData, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { getBudgetDimensions } from "api/budgets";
import Title from "antd/es/typography/Title";

const { Option } = Select;

const BudgetDimensions = ({ type, budget, hasValues }) => {
  const { t, i18n } = useTranslation();
  const [selectedDimensions, setSelectedDimensions] = useState([]);
  const [noMoreData, setNoMoreData] = useState(false);

  useEffect(() => {
    if (type === "edit" && budget?.dimensions) {
      const preSelected = budget.dimensions.map((el) => ({
        value: el.selectedDimension.value,
        code: el.selectedDimension.code,
        name_ar: el.selectedDimension.name_ar,
        name_en: el.selectedDimension.name_en,
      }));
      setSelectedDimensions(preSelected);
    }
  }, [type, budget]);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      "budgetDimensions",
      ({ pageParam = 1 }) => getBudgetDimensions(pageParam),
      {
        getNextPageParam: (lastPage) => {
          const nextPage = lastPage.pagination.next_page;
          return nextPage ? nextPage : undefined;
        },
      }
    );

  const dimensions = data?.pages.flatMap((page) => page.records) || [];

  const handleSelectChange = (value: any, field: FormListFieldData) => {
    setSelectedDimensions((prevSelectedDimensions) => {
      const newSelectedDimensions = [...prevSelectedDimensions];
      newSelectedDimensions[field.name] = value;
      return newSelectedDimensions;
    });
  };

  const handleRemove = (field: FormListFieldData) => {
    setSelectedDimensions((prevSelectedDimensions) =>
      prevSelectedDimensions.filter((_, index) => index !== field.name)
    );
  };

  const handlePopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollTop + clientHeight >= scrollHeight &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  };

  return (
    <>
      <Title level={4} style={{ fontWeight: 500 }}>
        {t("activerecord.attributes.contact.additional_information")}
      </Title>
      <Form.List name="dimensions">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Form.Item
                required={false}
                key={field.key}
                label={t(
                  "activerecord.attributes.dimension_value.dimension_code"
                )}
                style={{
                  maxWidth: 600,
                  fontSize: "12px",
                  fontWeight: "normal",
                }}
              >
                <Flex align="center" gap={10}>
                  <Form.Item
                    key={field.key}
                    {...field}
                    name={[field.name, "selectedDimension"]}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "budgets.budget_dimensions.validations.selection"
                        ),
                      },
                    ]}
                    style={{ flex: 1, marginRight: 8, marginBottom: 0 }}
                  >
                    <Select
                      disabled={hasValues}
                      labelInValue
                      onPopupScroll={handlePopupScroll}
                      placeholder={t(
                        "budgets.budget_dimensions.select_dimension"
                      )}
                      onChange={(value) => handleSelectChange(value, field)}
                      value={selectedDimensions[field.name]}
                    >
                      {dimensions?.map((el) => {
                        if (
                          !selectedDimensions.some(
                            (selected) => selected.value === el.id
                          )
                        ) {
                          return (
                            <Option key={el.id} value={el.id}>
                              {el.code}-
                              {i18n.language === "en" ? el.name_en : el.name_ar}
                            </Option>
                          );
                        }
                        return null;
                      })}
                    </Select>
                  </Form.Item>
                  {!hasValues && (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                        handleRemove(field);
                      }}
                      style={{ marginLeft: 8 }}
                    />
                  )}
                </Flex>
              </Form.Item>
            ))}
            <Form.Item>
              {!hasValues && (
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  {t("activerecord.attributes.dimension.new")}
                </Button>
              )}
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export default BudgetDimensions;
