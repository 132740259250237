import axios from "axios";
import { DimensionValue } from "types/dimensions";
import { QueryFunctionContext } from "react-query";


export const getDimension = async({ dimensionID }: {dimensionID: string}) => {
  return (await axios.get(`/tenant/dimensions/${dimensionID}.json`)).data
}

export const getDimensions = async ({ page=1 }: {page?: number}) => {
  return (await axios.get(`/tenant/dimensions.json?page=${page}`)).data
}

export const getConfiguration = async({ dimensionID }: {dimensionID: string}) => {
  return (await axios.get(`/tenant/dimensions/${dimensionID}/configurations.json?q[enabled_eq]=true`)).data
}

export const updateConfigurations = async () => {
  return (await axios.get(`/tenant/dimensions.json`)).data
}

export const getParentDimensionValues = async ({ dimensionID }: { dimensionID: string }, { pageParam }: QueryFunctionContext<any, number>) => {  
  const { data } = await axios.get(`/tenant/dimensions/${dimensionID}/values.json?page=${pageParam ?? 1}&q[value_type_eq]=parent_value`);
  return data;
}

export const getDimensionValues = async ({ dimensionID, page=1, parentID, cdType='', perPage=100, type='', valueID='' }: { dimensionID: number, cdType: string, page: number, type: string; parentID?: number | null | undefined , perPage?: number, valueID: string}) => {  
  let parentQueryParam = '';
  if(typeof parentID !== 'undefined') {
    parentQueryParam = parentID == null? 'q[parent_id_null]=true' : `q[parent_id_eq]=${parentID}`;
  }
  const { data } = await axios.get(`/tenant/dimensions/${dimensionID}/values.json?per_page=${perPage}&page=${page}&q[available_for_cd]=${cdType}&q[value_type_eq]=${type}&q[id_eq]=${valueID}&${parentQueryParam}`);
  return data;
}

export const updateDimension = async(id: number, {name_ar, name_en, description}: DimensionValue) => {  
  return (await axios.patch(`/tenant/dimensions/${id}.json`, {name_ar, name_en, description}));
}